<template>
  <v-card class="pa-0 text-right" elevation="0" tile>
    <Datatable ref="APIKeysDatatable"
               :api-endpoint="ENDPOINTS.DATATABLES.APIKEYS"
               :datatable-headers="datatableHeaders"
               no-excel-export
               show-delete-buttons
               permission-delete
               :custom-buttons="customButtons"
               @copy="copyToClipBoard"
               @deleteEntry="entryDelete"
               @deleteEntries="entriesDelete"
    />
  </v-card>
</template>

<script>
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "APIKeysComponent",
  components:{Datatable},
  data(){
    return{
      ENDPOINTS,
      customButtons: [
        {
          text: "pe-7s-copy-file",
          color: "success",
          eventRow: "copy",
          layout: {
            outlined: false,
            flat: false,
            text: false,
            icon: true,
          }
        }
      ],
      datatableHeaders:[
        {
          text:'ID',
          value:'id',
          width: 80,
          hide:true,
        },
        {
          text: this.$t('generic.lang_alias'),
          value:'alias',
        },
        {
          text: this.$t('settings.lang_apiKey'),
          value:'apiKey',
          width: '150px',
        },
        {
          text: this.$t('settings.lang_apiKeyType'),
          value:'APIKeyType',
          hide:true,

        },
      ]
    }
  },
  methods:{
    entryDelete(entry){
      this.id=entry.id;
      this.deleteData();
    },
    entriesDelete(entries){
      this.deleteData(entries)
    },
    deleteData(idsToDelete = []){
      let self = this;
      this.$swal({
        title: this.$t('settings.lang_deleteAPIKeyHead'),
        text: this.$t('settings.lang_deleteAPIKeyText'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post('delete/settings/apiKey/', {
            id: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_deletedSuccessfully'),
                color: "success"
              });
              self.$refs.APIKeysDatatable.getDataFromApi();
              self.$refs.APIKeysDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    copyToClipBoard(entry){
      navigator.clipboard.writeText(entry.apiKey);
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_copy2Clipboard'),
        color: "info"
      });
    }
  },
  mounted() {
    this.axios.post('get/settings/apiKey/', {
      id: 13,
    }).then((res) => {

    }).catch(err=>{

    })
  }
}
</script>

<style scoped>

</style>